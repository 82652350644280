*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
}



:root {
  --white-color: #ffffff;
  --blue-color: #29abe2;
  --faint-blue: #f5fcff;
  --gray-color: #717171;
  --black-color: #000000;
}

html {
  scroll-behavior: smooth;
}

body {
  min-height: 100vh;
  font-family: "Roboto", sans-serif;
  overflow-x: hidden;

  
  
}


body::-webkit-scrollbar {
  width: 6px;
}

body::-webkit-scrollbar-thumb {
  background-color: #d9d9d9;
  border-radius: 6px;
}

body::-webkit-scrollbar-thumb:hover {
  background-color: #8e8d8d; 
}



body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
figure,
blockquote,
dl,
dd {
  margin: 0;
}

ul,
ol[role="list"] {
  list-style-type: none;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }

  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
